@import '../../styles/shared.scss';

.homeRoot {
  padding: 67px 180px 106px 180px;
  margin: 0 auto;
  box-sizing: inherit;
  max-width: 1180px;
  width: auto;
}

.title {
  @extend .site-font;
  font-size: 44px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #333333;
  margin-bottom: 20px;
}

.message {
  text-align: center;
  margin-bottom: 50px;
}

.subMembershipContainer {
  margin-top: 60px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  gap: 20px;
}

@media (max-width: $XS) {
  .homeRoot {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 50px;
    text-align: center;
  }

  .title {
    font-size: 30px;
    margin-left: 50px;
    margin-right: 50px;
    margin-bottom: 25px;
  }
}

@media (min-width: $XS) {
  .homeRoot {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (min-width: 680px) {
  .homeRoot {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: 850px) {
  .homeRoot {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (min-width: $SM) {
  .homeRoot {
    padding-left: 40px;
    padding-right: 40px;
  }
}

@media (max-width: 1099px) {
  .subMembershipContainer {
    margin-top: 60px;
    display: grid;
    justify-items: center;
    gap: 20px;
    grid-template-columns: 1fr;
  }
}
