@import 'src/styles/shared.scss';

.paper {
  @extend .site-font;
  height: inherit;
  box-shadow: none;
  @media (min-width: $XS), (min-width: 0) {
    min-height: calc(100vh - 70px - 311px);
  }

  @media (min-width: $SM) {
    min-height: calc(100vh - 90px - 204px);
  }
}

.stepperRoot {
  background: none;
  border: none;
  padding: 0;
  margin-bottom: 18px;
  @media (min-width: 0), (min-width: $XS) {
    padding-top: 15px;
  }
  @media (min-width: $SM) {
    padding-top: 45px;
  }
}

.step {
  margin-bottom: 18px;
  padding: 0;
}

.line {
  top: auto;
  bottom: 22px;
  left: calc(-50% + 12px);
  right: calc(50% + 12px);
  :global(.MuiStepConnector-line.MuiStepConnector-alternativeLabel) {
    bottom: 24px;
  }
  :global(.MuiStepConnector-line) {
    border-width: 2px;
    border-color: #d7d7d7;
    border-radius: 1px;
  }
}

.activeLine {
  :global(.MuiStepConnector-line) {
    border-color: #467fd5;
  }
}

.completedLine {
  :global(.MuiStepConnector-line) {
    border-color: #467fd5;
  }
}

.iconRoot {
  display: flex;
  box-sizing: border-box;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  align-items: center;
  flex-direction: row-reverse;
  border-radius: 50%;
  position: relative;
  border: solid 2px #d7d7d7;
  background-color: #ffffff;
}

.icon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.active {
  z-index: 2;
  position: relative;
  border: solid 4px #467fd5;
}

.active.line {
  border-color: #784af4;
}

.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  font-size: 18px;
  border: 2px black;
  padding: 0;
}

.completed {
  @extend .iconRoot;
  border: solid 2px #467fd5;
  background-color: #ffffff;
  &::after {
    content: '';
    left: 5px;
    top: 6px;
    position: absolute;
    border: 2px solid #467fd5;
    width: 8px;
    border-top: 0;
    border-right: 0;
    height: 4px;
    transform: rotateZ(-56deg);
  }
}

.labelRoot {
  @extend .site-font;
  display: flex;
  flex-direction: column-reverse !important;
  font-size: 16px;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #999999;
  margin-bottom: 11px;
}

.activeLabel {
  @extend .labelRoot;
  color: #467fd5 !important;
}

.completedLabel {
  @extend .labelRoot;
  color: #467fd5 !important;
}

.widthContainer {
  max-width: 1200px;
  margin: 0 auto;
}
