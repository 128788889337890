.schoolName {
  font-style: italic;
}

.teamsList {
  list-style: disc;
  padding: 0 0 0 1rem;
  li {
    display: list-item;
  }
}
