@import 'src/styles/shared.scss';

.container {
  width: 100%;
  max-width: 1180px;
  padding-left: 0;
  padding-right: 0;
  // box-sizing: border-box;
  background-color: $footer-color;
  bottom: 0;
  @media (min-width: $XS), (min-width: 0) {
    display: flex;
    flex-direction: column;
    padding-top: 36px;
    padding-bottom: 30px;
    bottom: 0;
    // height: 320px;
  }
  @media (min-width: $SM) {
    display: grid;
    grid-template-areas:
      'links logoContainer'
      'copyrightContainer socialsContainer';
    padding-top: 43px;
    // padding-left: 80px;
    // padding-right: 80px;
    bottom: 0;
    // height: 223px;
  }

  @media (min-width: $MD) {
    padding-top: 43px;
    // padding-left: 130px;
    // padding-right: 130px;
    bottom: 0;
    // height: 223px;
  }
}

.logoContainer {
  height: 50px;
  margin-top: 36px;
  margin-bottom: 28px;
  grid-area: logoContainer;

  @media (min-width: $XS), (min-width: 0) {
    display: flex;
    justify-content: center;
    margin-top: 0;
  }

  @media (min-width: $SM) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    justify-self: end;
    padding-bottom: 25px;
    height: 60px;
  }
}

.logo {
  height: 100%;
}

.social {
  display: flex;
  color: $background-grey;
  font-size: 20px;
  margin-left: 24px;
  opacity: 0.5;
  &:nth-of-type(1) {
    margin-left: 0;
  }
  &:hover {
    opacity: 1;
  }
}

.paragraph {
  color: $white;
  text-align: center;
  font-size: 14px;
  line-height: 1;
  opacity: 0.85;
  text-decoration: none;
  &:nth-child(1) {
    margin-bottom: 20px;
  }
}

.copyrightContainer {
  grid-area: copyrightContainer;
  @media (min-width: $XS), (min-width: 0) {
    width: 100%;
  }
  @media (min-width: $SM) {
    display: flex;
    align-items: flex-start;
    border-top: 1px solid rgba($divider-grey, 0.2);
    padding-top: 25px;
  }
}

.copyright {
  @extend .paragraph;
  font-size: 12px;
  opacity: 0.5;
  margin-top: 0;
  @media (min-width: $XS), (min-width: 0) {
    margin-bottom: 0;
    &:nth-child(1) {
      margin-bottom: 5px;
    }
    &:nth-child(2) {
      margin-bottom: 0;
    }
  }
  @media (min-width: $SM) {
    font-size: 14px;
    opacity: 1;
    &:nth-child(1) {
      margin-right: 5px;
      margin-bottom: 0;
    }
  }
}

.socialsContainer {
  margin-bottom: 35px;
  align-items: center;
  grid-area: socialsContainer;
  align-self: end;

  @media (min-width: $XS), (min-width: 0) {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media (min-width: $SM) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 0;
    border-top: 1px solid rgba($divider-grey, 0.2);
    padding-top: 25px;
    align-self: start;
  }
}

.links {
  grid-area: links;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 35px;

  @media (max-width: $SM) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.alignmentContainer {
  // max-width: 1180px;
  display: flex;
  justify-content: center;
  background-color: $footer-color;
  width: 100%;
}

@media (max-width: 1090px) {
  .container {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media (max-width: 1279px) {
  .container {
    max-width: 1024px;
  }
}
