@import '../../../styles/shared.scss';

.container {
  background-color: $white;
  border-top: 5px solid $accent-color-1;
  box-shadow: 0 10px 25px 0 rgba(51, 51, 51, 0.07);
  width: auto;
}

.heading {
  font-size: 44px;
  font-weight: 900;
  color: #333333;
  margin-bottom: 40px;
}

.included {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  &:nth-child(14) {
    margin-bottom: 5px;
  }
}

.tickContainer {
  width: 24px;
  margin-right: 14px;
}

.price {
  font-size: 44px;
  font-weight: 500;
  margin: 0;
  color: #3e424e;
}

.imgContainer {
  background-color: $divider-grey;
  height: 100%;
  width: 100%;
}

.textContainer {
  padding: 40px;
}

.item {
  color: #3e424e;
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  // Align text with ticks
  margin-top: -1px;
  margin-left: -4px;
}

.buttonContainer {
  width: 140px;
}

.linkButton {
  border: none;
  text-decoration: underline;
  padding-left: 0;
  background-color: transparent;
}

.backgroundImg {
  background-size: contain;
  background-position: 50% 20%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.textItemContainer {
  display: flex;
  margin-top: 4px;
}

.listContainer {
  text-align: left;
}

@media (max-width: $XS) {
  .heading {
    font-size: 22px;
  }

  .included {
    text-align: left;
  }

  .buttonContainer {
    width: 100%;
  }
}

@media (min-width: 1280px) {
  .textContainer {
    margin-top: 0;
    max-width: 50%;
  }

  .imgGrid {
    max-width: 50%;
  }
}
