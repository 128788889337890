@import 'src/styles/shared.scss';

.list {
  margin: 4px 0 0 0;
  padding: 0;
  list-style: none;
  li {
    display: flex;
    &:before {
      content: '-';
      margin-right: 4px;
    }
  }
}

.title {
  text-transform: uppercase;
  color: #737680;
  font-size: 12px;
}

.row {
  margin-bottom: 24px;
  @media (max-width: $XS) {
    > :global(.MuiGrid-item + .MuiGrid-item) {
      margin-top: 24px;
    }
  }
}

.emptyStaffTypography {
  color: $red;
}
