@import '../../../styles/shared.scss';

.divider {
  height: 1px;
  background-color: rgba($divider-grey, 0.5);
  width:50%
}

.radioContainer {
  display: flex;
  align-items: center
}

.button {
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-weight: 600;
  // margin-bottom: 50px;
  padding: 0 20px !important;
}

.buttonBack {
  @extend .button;
  background-color: transparent;
  border: 1px solid #427fc0;
  width: 60px;
  margin-right: 20px;
}

.root {
  display: flex;
  margin-top: 30px;
  margin-bottom: 60px;
}
