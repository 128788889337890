@import '../../../styles/shared.scss';

.container {
  @extend .default-box-shadow;
  background-color: $white;
  padding-left: 15px;
  padding-right: 15px;
  height: 70px;
  width: 100%;
  position: relative;
  z-index: 10;
  justify-content: center;
  padding: 0;

  @media (min-width: $XS) {
    // padding-left: 40px;
    // padding-right: 40px;
    height: 70px;
  }

  @media (min-width: $SM) {
    // padding-left: 80px;
    // padding-right: 80px;
    height: 90px;
  }

  @media (min-width: $MD) {
    // padding-left: 130px;
    // padding-right: 130px;
    height: 90px;
  }
}

.buttonContainer {
  margin-left: auto;
}

.button {
  color: #333333 !important;
  &:hover {
    border: none !important;
  }
}

.userButton {
  @extend .button;
  display: flex;
  align-items: center;
}

.logoutButton {
  @extend .button;
  display: flex;
  align-items: center;
  padding: 0 10px 0 10px;
  color: $brand-cta-color;
}

.logoContainer {
  height: 60px;
  @media (max-width: $SM) {
    height: 45px;
  }
}

.logo {
  @extend .pointer;
  height: 100%;
}

.logoutIcon {
  margin-left: 5px;
  margin-right: 15px;
  color: $brand-cta-color;
}

.alignmentContainer {
  max-width: 1180px;
  width: 100%;
}

@media (max-width: 1080px) {
  .alignmentContainer {
    padding-left: 40px;
  }
}

@media (max-width: $MD) {
  .alignmentContainer {
    max-width: 1024px;
  }
}
