@import 'src/styles/shared.scss';

.membershipSummary {
  @extend .primary-border;
  margin-bottom: 40px;
}

.summaryTitle {
  height: 72px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  padding-left: 29px;
  @media (max-width: $MD) {
    padding-left: 20px;
  }
}

.referenceNumber {
  font-size: 15px;
  color: #3e424e;
  line-height: 1.5;
  font-weight: 600;
}

.manageTeamButtonContainer {
  > * + * {
    margin-left: 20px;
  }

  @media (max-width: $XS) {
    > * {
      width: 100%;
      text-align: center;
    }
    > * + * {
      margin-left: 0;
      margin-top: 20px;
    }
  }
}

.manageTeamButton {
  color: #fff;
  background-color: $site-color;
  padding: 10px 16px;
  text-decoration: none;
}

.manageSpacing {
  @extend .manageTeamButton;
  margin-left: 15px;
}

.mdSeparator {
  margin: 0 20px;
}

.container {
  padding-bottom: 80px;
}

.invoiceButton {
  border-radius: 0;
  font-weight: 500
}

.downloadInvoiceButton {
  text-decoration: none;
  color: $brand-cta-color;
  font-weight: 500;
  height: 36px;
  border: 1px solid $brand-cta-color;
  display: flex;
  align-items: center;
  padding: 0px 14px;
  width: fit-content;
}

.changePaymentContainer {
  border-top: 1px solid $divider-grey;
}

.invoiceContainer {
  margin-bottom: 50px;
  font-size: 14px;
}
