@import '../../../styles/shared.scss';

.radioContainer {
  display: flex;
  align-items: center
}

.paper {
  min-width: 300px;
  padding: 20px;
}

.flex {
  margin-top: 30px;
  display: flex;
}

.button {
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-weight: 600;
  // margin-bottom: 50px;
  padding: 0 10px !important;
}

.buttonBack {
  @extend .button;
  background-color: transparent;
  border: 1px solid #427fc0;
  width: 60px;
  margin-right: 20px;
}

.warning {
  color: $red
}