@import 'src/styles/shared.scss';

.header {
  font-size: 30px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.1;
  letter-spacing: normal;
  color: #333333;
}

.paymentLabel {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #333333;
}

.membershipSummary {
  @extend .primary-border;
  margin-bottom: 40px;
  margin-top: 35px;
}

.summaryTitle {
  height: 72px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  padding-left: 29px;
}

.summaryContent {
  padding: 29px;
}

.summaryTotal {
  display: flex;
  justify-content: flex-end;
  padding-right: 29px;
}

.chipInput {
  border: 1px solid #f8fafd;
  border-radius: 0;
  margin-left: 51px;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
  color: #333333;
  height: fit-content;
  border: solid 1px #d7d7d7;
  background-color: #ffffff;
  padding-left: 16px;
  align-items: center;
  min-height: 54px;
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
}

.selected {
  border: 1px solid red;
}

.chip {
  border-radius: 3px;
  border: solid 1px #e4e4e4;
  background-color: #f6f6f6;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #2d3236;
  margin: 0 8px 0 0;
  height: 25px;
  align-items: center;
}

.icon {
  width: 16px;
  height: 16px;
}

.error {
  margin-left: 51px;
}

.inputSelected {
  border: 1px solid red;
  border-radius: 0;
  margin-left: 43px;
  width: 100%;
  &:focus {
    border: 1px solid red !important;
  }
  &:active {
    border: 1px solid red !important;
  }
}

.expanded {
  height: fit-content;
  padding-top: 20px;
}

.label {
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  color: #2d3236;
}

.grid {
  margin-top: 6px;
  margin-bottom: 20px;
}

.logoContainer {
  height: 60px;
  @media (max-width: $SM) {
    height: 45px;
  }
}

.logo {
  @extend .pointer;
  height: 100%;
}

.formContainer {
  width: 100%;
}

.submitButton {
  font-family: unset;
  font-size: 14px;
  // padding: $padding-fix 14px 0;
  // min-width: 75px;
  width: 100%;
  height: 50px;
  background-color: $brand-cta-color;
  border: none;
  margin-top: 30px;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  color: #ffffff;
}

.FormGroup {
  width: 100%;
  /* margin: 0 15px 20px; */
  padding: 0;
  border-style: none;
  /* background-color: #7795f8; */
  /* will-change: opacity, transform; */
  /* box-shadow: 0 6px 9px rgba(50, 50, 93, 0.06), 0 2px 5px rgba(0, 0, 0, 0.08), inset 0 1px 0 #829fff; */
  /* border-radius: 4px; */
}

.FormRow {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.StripeElement--webkit-autofill {
  background: transparent !important;
}

.StripeElement {
  // width: 100%;
  padding: 11px 15px 11px 0;
}

.CardElement {
  width: 100%;
  border-bottom: 1px solid #d7d7d7;
  padding: 11px 15px 11px 0;
  // margin-bottom: 30px;
}

.CardElementInvalid {
  width: 100%;
  border-bottom: 1px solid #767676;
  padding: 11px 15px 11px 0;
  color: #d31f2a;
}

.CardExpiryElement {
  // width: 100%;
  border-bottom: 1px solid #d7d7d7;
  // margin-right: 10px;
  margin-top: 20px;
  padding: 11px 15px 11px 0;
}

.CardExpiryElementInvalid {
  // width: 100%;
  border-bottom: 1px solid #767676;
  // margin-right: 10px;
  padding: 11px 15px 11px 0;
  color: #d31f2a;
}

.CardCVCElement {
  // width: 100%;
  // margin-left: 10px;
  margin-top: 20px;
  border-bottom: 1px solid #d7d7d7;
  padding: 11px 15px 11px 0;
}

.CardCVCElementInvalid {
  // width: 100%;
  border-bottom: 1px solid #767676;
  padding: 11px 15px 11px 0;
  color: #d31f2a;
}

.spinner {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.chipInnerInput {
  @extend .site-font;
  font-size: 14px;
}
