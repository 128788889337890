@import '../../../styles/shared.scss';

.inputOutlined {
  height: 40px;
  padding: 0 20px 0 10px;
  min-width: 150px;
  border: $primary-border-color 1px solid !important;
  border-radius: 3px;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #333333;
  padding-left: 16px;
  border-radius: 0;
  -webkit-appearance: none;
  width: 100%;
}
