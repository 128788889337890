@import '../../../styles/shared.scss';

.container {
  @extend .pointer;
  width: 100%;
  border-radius: 0;
  position: relative;
}

:global {
  div.cs-react-select__value-container {
    height: 40px;
    padding-left: 50px;
  }

  div.cs-react-select__option {
    padding: 0;
    padding-top: 6px;
    &:nth-child(1) {
      padding-top: 0;
    }
    background-color: transparent;
    border-radius: 0;
    // box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1);
    box-shadow: transparent;
  }

  div.cs-react-select__option {
    background-color: transparent;
    cursor: pointer;
    &:hover {
      background-color: transparent;
    }
  }

  div.cs-react-select__control {
    border: #eaecf1 1px solid;
    border-radius: 0;
    box-shadow: none;
    &:hover,
    &:focus,
    &:active {
      border-color: #eaecf1;
      cursor: pointer;
    }
  }

  div.cs-react-select__menu {
    border-radius: 0;
    margin: 0;
  }

  div.cs-react-select__menu-list {
    padding: 12px;
    // padding-left: 15px;
  }

  div.cs-react-select__menu-notice {
    padding: 0;
  }

  div.cs-react-select__dropdown-indicator {
    display: none;
  }

  span.cs-react-select__indicator-separator {
    display: none;
  }

  // div.cs-react-select__indicators {
  //   display: none;
  // }

  div.cs-react-select__indicator:hover {
    cursor: pointer;
  }
}
