@import 'src/styles/shared.scss';

.title {
  height: 72px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  padding-left: 29px;
  @media (max-width: $MD) {
    padding-left: 20px;
  }
}

.staffDetails {
  @extend .primary-border;
  margin-top: 40px;
}

.staffDetailItem {
  padding: 32px;
  & + .staffDetailItem {
    border-left: 1px solid $divider-grey;
  }
  @media (max-width: $SM) {
    padding: 20px;
    & + .staffDetailItem {
      border-left: none;
    }
  }
}
