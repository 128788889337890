@import 'src/styles/shared.scss';

.primaryText {
  font-size: 14px;
  line-height: 1.6;
  margin-right: 20px;
  margin-left: 14px;
}

.listItem {
  display: flex;
  align-items: center;
  height: 90px;
  border: 1px solid $divider-grey;
  position: relative;
  padding-right: 20px;
  + .listItem {
    border-top: none;
  }
  &:hover {
    background-color: #f8fafd;
  }
}

.itemSelected {
  background-color: #f8fafd !important;
}
