@import 'src/styles/shared.scss';

.membershipSummary {
  @extend .primary-border;
  margin-bottom: 40px;
}

.summaryTitle {
  height: 72px;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
  padding-left: 29px;
}

.itaId {
  font-size: 15px;
  color: #3e424e;
  line-height: 1.5;
  font-weight: 600;
}

.link {
  text-decoration: none;
  color: #467fd5;
}
