.button {
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-weight: 600;
  // margin-bottom: 50px;
  padding: 0 10px !important;
}

.buttonBack {
  @extend .button;
  background-color: transparent;
  border: 1px solid #427fc0;
  width: 60px;
  margin-right: 20px;
}

.root {
  display: flex;
}

.actionButtonContainer {
  margin-top: 55px;
  margin-bottom: 40px;
}

.searchIcon {
  position: absolute;
  height: 40px;
  padding-left: 20px;
}
