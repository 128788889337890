@import 'src/styles/shared.scss';

.paper {
  @extend .site-font;
  height: inherit;
  @media (min-width: $XS), (min-width: 0) {
    min-height: calc(100vh - 70px - 311px);
  }

  @media (min-width: $SM) {
    min-height: calc(100vh - 90px - 204px);
  }
}
