@font-face {
  font-family: Avenir;
  src: url('../assets/37C23B_2_0 copy.woff2') format('woff2');
  // src: url('../assets/37C23B_2_0.woff2') format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: Avenir;
  src: url('../assets/37C23B_6_0 copy.woff2') format('woff2');
  // src: url('../assets/37C23B_2_0.woff2') format('woff2');
  font-weight: 500;
}

@font-face {
  font-family: Avenir;
  src: url('../assets/37C23B_8_0 copy.woff2') format('woff2');
  // src: url('../assets/37C23B_2_0.woff2') format('woff2');
  font-weight: 700;
}

$site-color: #467fd5; // For call to actions, links and section highlights
$dark-grey: #333333; // Main type colour, dark outlines or for dark buttons if required
$mid-gray: #999999; // Used for inactive and suggestion text
$semi-dark-grey: #767676; //Used for lighter secondary text below headings
$form-outline-grey: #d7d7d7; // Use for the outline in form elments
$divider-grey: #d7d7d7; // Use for dividers, panel outlines or horizontal rules
$background-grey: #f6f6f6; // For the footer background and any light grey backgrounds for page sections or in-page components
$accent-color-1: #fcd350; // charts or icons
$accent-color-2: #09315c; // charts or icons
$red: #d31f2a; // For error states, or red text and error state UI elements.
$orange: #e46600; // For ‘Limited availaibility’ tags or pending state UI elements.
$green: #148901; // Green text and Success state UI elements.

$page-bg: #f6f6f8;
$primary-text-bg: #fff;
$primary-border-color: #e9ebf0;
$text-inverted: #fff;
$footer-color: #2a3041;

// Core brand colours
$brand-cta-color: #4b90d9; // call to actions & links
$secondary-color: #06143f; // charts or icons

$white: #ffffff; // background to cards, panels and the header

// Dimensions
$sidebar-width: 70px;

// Typography
.site-font {
  font-family: 'Avenir', sans-serif;
}

.body-base {
  @extend .site-font;
  color: $dark-grey;
}

.body-regular {
  @extend .body-base;
  font-size: 14px;
  font-weight: normal;
}

.body-large {
  @extend .body-base;
  font-size: 15px;
  font-weight: normal;
}

.body-small {
  @extend .body-base;
  font-size: 13px;
  font-weight: normal;
}

.body-small-bold {
  @extend .body-base;
  font-size: 13px;
  font-weight: bold;
}

// Shadows
.shadow-box-light {
  box-shadow: 0px 7px 25px rgba(0, 0, 0, 0.08);
}

.default-box-shadow {
  box-shadow: 0 5px 20px 0 rgba(51, 51, 51, 0.06);
}

// etc.
.page-bg {
  background-color: $page-bg;
}

.hover-link {
  color: $semi-dark-grey;
  &:hover {
    color: $brand-cta-color;
  }
}

.click-link {
  color: $semi-dark-grey;
  &:active {
    color: $brand-cta-color;
  }
}

.hover-row {
  cursor: pointer;
  &:hover {
    background-color: $background-grey;
  }
}

.text-inverted {
  color: #fff;
}

.text-medium-dark {
  color: #666666;
}

.text-input {
  height: 40px;
  padding: 0 20px 0 10px;
  min-width: 150px;
  font-weight: 200;
  font-size: small;
  background-color: $primary-text-bg;
}

.primary-border {
  border: $divider-grey 1px solid;
}

.border-top {
  border-style: solid none none none;
}

.flex-col {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.h3 {
  font-size: 20px;
  font-weight: bold;
}

.membership-label {
  margin-top: 15px;
  margin-bottom: 10px;
}

.membership-header {
  display: flex;
  align-items: center;
  border-bottom: 1px solid $primary-border-color;
  margin: 0;
  margin-bottom: 25px;
  padding-bottom: 30px;
}

.membership-heading {
  font-size: 20px;
  margin: 0;
}

.membership-container {
  @extend .flex-col;
  @extend .primary-border;
  background-color: $primary-text-bg;
  padding: 35px;
}

.h4 {
  font-size: 16px;
  font-weight: bold;
}

.unstyled-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-family: unset;
  padding: unset;
  color: unset;
  font-size: inherit;
}

.form-error-message {
  @extend .site-font;
  font-size: small;
  color: $red;
}

.pointer {
  &:hover {
    cursor: pointer;
  }
}

// Material-UI Breakpoints
$XS: 600px;
$SM: 960px;
$MD: 1280px;
$LG: 1920px;
$XL: 2500px;
