@import '../../../styles/shared.scss';

.container {
  position: relative;
  background-color: $white;
  padding: 35px 45px 40px 45px;
  padding-left: 35px;
  padding-right: 35px;
  height: 100%;
  text-align: center;
  border-top: 5px solid $accent-color-1;
  min-height: 720px;
}

.included {
  display: flex;
  align-items: flex-start;
  // margin-bottom: 18px;
  text-align: left;
}

.tickIcon {
  margin-right: 14px;
  font-size: 24px;
}

.tickContainer {
  margin-right: 14px;
  width: 24px;
}

.price {
  font-size: 44px;
  font-weight: 500;
  color: #3e424e;
  margin-top: 22px;
  margin-bottom: 22px;
}

.linkButton {
  border: none;
  text-decoration: underline;
  padding-left: 0;
  background-color: transparent;
}

.item {
  color: #3e424e;
  font-size: 14px;
  font-weight: 300;
}

.includedList {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 210px;
}

.textItemContainer {
  display: flex;
  margin-top: 4px;
}

.rates {
  margin-top: 20px;

  .priceContainer {
    display: flex;
    margin-bottom: 15px;

    .name {
      text-align: left;
      margin-right: 20px;
    }

    .coachPrice {
      margin-left: auto;
    }
  }
}

.headingWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 60px;
}

.subTextWrapper {
  margin-top: 20px;
  height: 95px;
  display: flex;
  align-items: center;
}

.benefitsWrapper {
  margin-top: 35px;
  min-height: 285px;
  display: flex;
  align-items: flex-start;
}

.spaceFiller {
  height: 46px;
}

@media (min-height: 0) {
  .container {
    min-height: 550px;
  }

  .subTextWrapper {
    height: 60px;
  }

  .benefitsWrapper {
    min-height: 175px;
  }
}

@media (min-width: 500px) {
  .container {
    min-height: 500px;
  }

  .benefitsWrapper {
    min-height: 175px;
  }
}

@media (min-width: 1100px) {
  .benefitsWrapper {
    min-height: 385px;
  }

  .subTextWrapper {
    height: 95px;
  }
}

@media (min-width: $MD) {
  .container {
    min-height: 650px;
  }
}

@media (min-width: $MD) {
  .benefitsWrapper {
    min-height: 325px;
  }
}
