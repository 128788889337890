@import '../../../styles/shared.scss';

.select {
  @extend .text-input;
  border-radius: 0;
  padding-left: 0;
  padding-right: 0;
  height: 40px;
  border: 1px solid #eaecf1;
  &:hover {
    border-color: #eaecf1;
  }
  &:before {
    border-color: #eaecf1;
  }
  &:after {
    border-color: #eaecf1;
  }
}

.selectOverride {
  padding: 12px;
}

.rootOverride {
  @extend .site-font;
  padding-left: 16px;
  &:focus {
    background-color: transparent;
  }
}
