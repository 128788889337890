@import '../styles/shared.scss';

*,
html,
body,
.root {
  @extend .site-font;
}

.root {
  height: 100%;
  box-sizing: border-box;
}

.router,
.container {
  width: 100%;
  @media (min-width: $XS), (min-width: 0) {
    min-height: calc(100vh - 70px - 311px);
  }

  @media (min-width: $SM), (min-width: $SM) {
    min-height: calc(100vh - 90px - 204px);
  }
}
