@import '../../../styles/shared.scss';

.tableContainer {
  padding: 36px 30px;
  @media (max-width: $MD) {
    padding: 20px;
  }
}
.dates {
  font-size: 14px;
  color: #767676;
  margin: 0;
}

.divider {
  height: 2px;
  background-color: $dark-grey;
  margin-top: 30px;
}

.containerTotal {
  margin-top: 30px;
}

.selectedSchool {
  margin-top: 16px;
}

.typographyTotal {
  font-size: 20px;
}

.typographyMembership {
  text-transform: uppercase;
  color: $semi-dark-grey;
}
