@import '../../../styles/shared.scss';

.button {
  border-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  font-weight: 600;
  // margin-bottom: 50px;
  padding: 0 10px !important;
  margin-bottom: 80px;
}

.inputOutlined {
  height: 40px;
  padding: 0 20px 0 10px;
  min-width: 150px;
  border: $primary-border-color 1px solid !important;
  border-radius: 0;
  padding: 0;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #333333;
  padding-left: 16px;
  border-radius: 0;
  -webkit-appearance: none;
  width: 100%;
  box-sizing: border-box;
}
