@import '../../../styles/shared.scss';

.container {
  position: absolute;
  z-index: 10;
  padding: 15px 20px;
  padding-top: 13px;
  // padding-top: 13px;
  // padding-bottom: 15px;
  width: 100%;
  border-top: $divider-grey 1px solid;
  border-bottom: $divider-grey 1px solid;
  background-color: $white;
}

.summary {
  border: 2px solid black;
  padding: 25px;
  margin-left: auto;
}

.expanded {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 5;
  background-color: rgba(0, 0, 0, 0.35);
}

.fadeBackground {
  display: none;
}

.regular {
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  background-color: $white;
  border-top: $divider-grey 1px solid;
  border-bottom: $divider-grey 1px solid;
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.button {
  border: none;
  text-decoration: underline;
  padding-left: 0;
  background-color: transparent;
  height: 25px;
}

.empty {
}

.hidden {
  display: none;
}

.totalContainer {
  margin-top: 20px;
  padding-top: 20px;
  border-top: $divider-grey 1px solid;
}

.discountContainer {
  margin-top: 15px;
}

.expandedHeading {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
  margin-bottom: 15px;
}

.condensedHeading {
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}

.separator {
  height: 1px;
  width: 100%;
  background-color: $divider-grey;
}

.selectedSchool {
  margin-top: 20px;
  @media (max-width: $XS) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.discountContainer {
  margin-top: 20px;
  @media (max-width: $XS) {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}

.discountText {
  color: $green;
}
